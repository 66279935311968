<template>
  <ion-header>
    <ion-toolbar color="light">
      <ion-buttons id="back-btn" v-if="showBackBtn" slot="start">
        <ion-back-button v-if="activePartner && activePartner.url_path" :default-href="'/' + activePartner.url_path + '/home'" text="zurück" icon="chevron-back"></ion-back-button>
      </ion-buttons>
      <ion-buttons v-if="showMenu" slot="end">
        <ion-menu-button  auto-hide="false" color="primary">
          <ion-icon size="large" part="icon" icon="menu-sharp" mode="md" aria-hidden="true"></ion-icon>
        </ion-menu-button>
      </ion-buttons>
      <ion-title>
        <div class="navigation__images" :class="{'logo-negative-left': showBackBtn, 'logo-negative-right': showMenu, }">
          <ion-img v-if="activePartner && activePartner.url_path" :router-link="{ name: 'home', params: { url_path: activePartner.url_path}}" class="logo-img" :src="baseUrl + '/storage/images/' + activePartner.img" alt="logo"></ion-img>
          <svg class="navigation__bottle_o_at" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M12.042 23.648c-7.813 0-12.042-4.876-12.042-11.171 0-6.727 4.762-12.125 13.276-12.125 6.214 0 10.724 4.038 10.724 9.601 0 8.712-10.33 11.012-9.812 6.042-.71 1.108-1.854 2.354-4.053 2.354-2.516 0-4.08-1.842-4.08-4.807 0-4.444 2.921-8.199 6.379-8.199 1.659 0 2.8.876 3.277 2.221l.464-1.632h2.338c-.244.832-2.321 8.527-2.321 8.527-.648 2.666 1.35 2.713 3.122 1.297 3.329-2.58 3.501-9.327-.998-12.141-4.821-2.891-15.795-1.102-15.795 8.693 0 5.611 3.95 9.381 9.829 9.381 3.436 0 5.542-.93 7.295-1.948l1.177 1.698c-1.711.966-4.461 2.209-8.78 2.209zm-2.344-14.305c-.715 1.34-1.177 3.076-1.177 4.424 0 3.61 3.522 3.633 5.252.239.712-1.394 1.171-3.171 1.171-4.529 0-2.917-3.495-3.434-5.246-.134z"/></svg>
          <svg class="navigation__bottle_o_logo" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 137.9 147.1" xml:space="preserve"><g><circle class="st0" cx="116.3" cy="35.2" r="14.8"/><circle class="st0" cx="95.6" cy="12.6" r="8.7"/><circle class="st0" cx="88.6" cy="37.5" r="5.7"/><path class="st0" d="M55.2 41.1C26.5 41.1 5.4 63 5.4 92.3c0 27.9 24.6 46.8 50.9 46.8 27.1 0 48.7-21.7 48.7-49.6.1-27.1-22.2-48.4-49.8-48.4zm.1 76c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27z"/></g></svg>
        </div>
        
        
<!--        <div class="logo">-->
<!--          <div><ion-img :router-link="`/home/`" class="logo-img" :class="{'logo-negative-left': showBackBtn, 'logo-negative-right': showMenu, }" src="/assets/img/entres.png" alt="logo"></ion-img></div>-->
<!--          <div><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" data-v-4a3fd86c=""><path d="M12.042 23.648c-7.813 0-12.042-4.876-12.042-11.171 0-6.727 4.762-12.125 13.276-12.125 6.214 0 10.724 4.038 10.724 9.601 0 8.712-10.33 11.012-9.812 6.042-.71 1.108-1.854 2.354-4.053 2.354-2.516 0-4.08-1.842-4.08-4.807 0-4.444 2.921-8.199 6.379-8.199 1.659 0 2.8.876 3.277 2.221l.464-1.632h2.338c-.244.832-2.321 8.527-2.321 8.527-.648 2.666 1.35 2.713 3.122 1.297 3.329-2.58 3.501-9.327-.998-12.141-4.821-2.891-15.795-1.102-15.795 8.693 0 5.611 3.95 9.381 9.829 9.381 3.436 0 5.542-.93 7.295-1.948l1.177 1.698c-1.711.966-4.461 2.209-8.78 2.209zm-2.344-14.305c-.715 1.34-1.177 3.076-1.177 4.424 0 3.61 3.522 3.633 5.252.239.712-1.394 1.171-3.171 1.171-4.529 0-2.917-3.495-3.434-5.246-.134z" data-v-4a3fd86c=""></path></svg></div>-->
<!--          <div><svg class="navigation__bottle_o_logo" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 137.9 147.1" xml:space="preserve" data-v-4a3fd86c=""><g data-v-4a3fd86c=""><circle class="st0" cx="116.3" cy="35.2" r="14.8" data-v-4a3fd86c=""></circle><circle class="st0" cx="95.6" cy="12.6" r="8.7" data-v-4a3fd86c=""></circle><circle class="st0" cx="88.6" cy="37.5" r="5.7" data-v-4a3fd86c=""></circle>-->
<!--            <path class="st0" d="M55.2 41.1C26.5 41.1 5.4 63 5.4 92.3c0 27.9 24.6 46.8 50.9 46.8 27.1 0 48.7-21.7 48.7-49.6.1-27.1-22.2-48.4-49.8-48.4zm.1 76c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27z" data-v-4a3fd86c=""></path></g>-->
<!--          </svg></div>-->
<!--        </div>-->
      </ion-title>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonMenuButton,
  IonImg,
  IonBackButton,
  IonButtons
} from '@ionic/vue'
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue';
import { baseUrl} from "@/api/fetch";

export default defineComponent({
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonMenuButton,
    IonImg,
    IonBackButton,
    IonButtons
  },
  computed: {
    ...mapGetters('app', [
      'activePartner',
    ]),
    ...mapGetters('auth', [
      'userprofile',
      'isLogedIn'
    ]),
    showBackBtn() {
      const excluded = ['stamp-card', 'customer-card', 'coupon', 'promotion', 'home', 'Login']
      return !excluded.includes(this.$route.name)
    },
    showMenu() {
      return this.isLogedIn
    },
    baseUrl() {
      return baseUrl;
    }
  },
});
</script>
<style scoped>
  .logo_bottle-o {
    width: 36px;
  }

  .logo-negative-left {
    padding-left: -118px;
  }
  
  .logo-negative-right {
    padding-right: -52px;
  }
  
  .navigation__images {
    display: flex;
    justify-content: center;
    align-items: center;
    /*display: grid;*/
    /*grid-template-columns: repeat(3 , max-content);*/
    /*align-items: center;*/
    /*margin: 0 auto;*/
  }

  .navigation__bottle_o_logo {
    width: 37px;
    fill: var(--ion-color-primary);
  }

  .navigation__bottle_o_at {
    margin: 0 .5rem;
    flex-shrink: 0;
  }

  
  
  .logo-img::part(image) {
    max-height: 36px;
  }

  @media (max-width: 400px) {
    .logo-negative-left {
      padding-left: -52px;
    }
    
    ion-back-button::part(text) {
      display: none !important;
    }
  }
</style>