<template>
  <base-modal :force="force" :title="this.partner.name + ' verknüpfen'">
    <ion-item class="ion-margin-top">
      <ion-label class="ion-text-wrap">Ich akzeptiere die <a role="button" tabindex="0" @click="openModalPrivacyPolicy(this.partner)">Datenschutzbestimmungen</a> von {{ this.partner.name }}</ion-label>
      <ion-checkbox 
        slot="start"
        @update:modelValue="form.privacy_policy = $event"
        :modelValue="form.privacy_policy">   
      </ion-checkbox>
    </ion-item>
    <section align="right" class="ion-margin-top">
      <ion-button @click="submitModal" :disabled="v$.form.$invalid">Bestätigen</ion-button>
      <ion-button v-if="!force" @click="closeModal()" color="secondary">Zurück</ion-button>
    </section>
  </base-modal>
</template>

<script>
import {
  IonCheckbox,
  IonButton,
  IonItem,
  IonLabel, modalController
} from '@ionic/vue'
import BaseModal from "@/components/modals/BaseModal";
import {mapActions} from "vuex";
import { useVuelidate } from '@vuelidate/core'
import {sameAs, required} from "@vuelidate/validators";
import ModalPrivacyPolicy from "@/components/modals/ModalPrivacyPolicy";

export default {
  components: {
    BaseModal,
    IonButton,
    IonCheckbox,
    IonItem,
    IonLabel
  },
  data() {
    return {
      form: {
        privacy_policy: false
      },
      partner: this.data.partner,
      force: this.data.force
    }
  },
  props: {
    parent: { type: Object, required: true },
    data: { parent: { type: Object, required: true }, type: Object, required: true }
  },
  name: "LinkPartnerModal",
  setup: () => ({
    v$: useVuelidate()
  }),
  validations () {
    return {
      form: {
        privacy_policy: { sameAs: sameAs(true) }
      }
    }
  },
  methods: {
    ...mapActions('partners', [
      'activatePartner'
    ]),
    submitModal() {
      this.activatePartner(this.partner)
      this.parent.closeModal()
    },
    closeModal() {
      if(!this.force) {
        this.parent.closeModal()
      }      
    },
    async openModalPrivacyPolicy(partner) {
      const modal = await modalController
        .create({
          component: ModalPrivacyPolicy,
          cssClass: 'modul_privacy_policy',
          componentProps: {
            title: 'Datenschutz',
            partner: partner
          }
        })
      return modal.present();
    },
  }
}
</script>

<style scoped>

</style>