import { Network } from '@capacitor/network'

export const app = {
    namespaced: true,
    state: () => ({
      activePartnerObj: null, //selected-Partner
      baseConfigurationObj: null,
      network: {
          status: null,
          until: new Date()
      },
      cachedRequests: [],
      apiEndpoints: [],
      lastUpdate: null,
      errors: []
    }),
    getters: {
      networkStatus(state) {
        return state.network.status
      },
      activePartner(state) {
        return state.activePartnerObj
      },
      baseConfiguration(state) {
        return state.baseConfigurationObj;
      }
    },
    mutations: {
      setActivePartnerObj(state, partner) {
        state.activePartnerObj = partner
      },
      setBaseConfigurationObj(state, baseConfig){
        state.baseConfigurationObj = baseConfig;
      },
      addError(state, error) {
        state.errors.push(error)
      },
      pushCacheRequest(state, config) {
        state.cachedRequests.push(config)
      },
      setNetworkStatus(state, status) {
        state.networkStatus = status
        
        state.network.status = status
        state.network.until = new Date()
      }
    },
    actions: {
        async freshNetworkStatus({commit}) {
            const status = await Network.getStatus()
            commit('setNetworkStatus', status)
            
            return status
        },
        async fetchApiEndpoints() {
            return this.$http(process.env.VUE_APP_API_ENDPOINTS)
                .then((response) => {
                return response
            })
        },
        async fetchBaseConfiguration({commit}) {
          const baseConfig = await this.$http(this.$apiRoute('api.basic-configuration'));
          await commit('setBaseConfigurationObj', baseConfig.data)
          return baseConfig.data;
        },
        cacheRequest({commit}, requestError) {
          commit('pushCacheRequest', requestError.config)
        }
    }
}