import router from "@/router";
import store from "@/store";

export const partners = {
    namespaced: true,
    state: () => ({
        partners: []
    }),
    mutations: {
        setPartners(state, partners) {
            state.partners = partners
        },
    },
    getters: {
      partner: (state) => (id) => {
        return state.partners.find((partner) => partner.id === id)
      },
      partners(state) {
          return state.partners
      },
      storesForActivePartner(state, getters) {
        return getters.partner(store.state.app.activePartnerObj.id)?.stores
      },
    },
    actions: {
      async fetchPartners({commit}) {
          const partners = await this.$http.get(this.$apiRoute('api.partners.index'))
          await commit('setPartners', partners.data)
        },
        async activatePartner({commit, dispatch}, payload) {
          await commit('app/setActivePartnerObj', payload, { root: true });
          await this.$http(this.$apiRoute('api.partner.activate'), {
            method: 'POST',
            body: JSON.stringify({partner_id: payload.id})
          })
          
          await router.push({ name: 'home', params: { 'url_path': payload.url_path}})
          await store.dispatch('cards/fetchCards')
        }
    }
}