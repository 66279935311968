import unauth from './401.js'
import UnprocessableEntity from './422.js'
import ServerError from './500.js'

export default async (error) => {
    
    switch (error.response?.status) {
        case 401: 
            unauth(error)
            break;
        case 422:
            UnprocessableEntity(error)
            break;
        case 500:
            ServerError(error)
    }
    
    return Promise.reject(error)
    // throw error
}