export const promotions = {
  namespaced: true,
  state: () => ({
    data: []
  }),
  mutations: {
    setPromotions(state, promotions) {
      state.data = promotions
    },
  },
  getters: {
    promotion: (state) => (id) => {
      return state.data.find((promotion) => promotion.id === parseInt(id))
    },
    promotions: (state, getters, rootState) => {
      return state.data 
    },
    allPromotions(state) {
      return state.data
    },
    stampCards: (state, getters) => {
      return getters.promotions.filter(x => x.type === 'stamp-card')
    },
    stampCardsCount: (state, getters) => {
      return getters.stampCards.length
    },
    actionPromotions: (state, getters) => {
      return getters.promotions.filter(x => x.type === 'deal')
    },
    featuredActionPromotions: (state, getters) => {
      return getters.promotions.filter(x => x.is_featured)
    },
    couponPromotions: (state, getters) => {
      return getters.promotions.filter(x => x.type === 'coupon')
    },
    couponsCount: (state, getters) => {
      return getters.couponPromotions.length
    },
  },
  actions: {
    async fetchPromotions({commit}) {
      
      const promotions = await this.$http( this.$apiRoute('api.promotions.index'), {
        method: 'GET'
      })
      
      commit('setPromotions', promotions.data)
    }
  }
}