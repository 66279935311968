<template>
  <ion-menu side="start" menu-id="first" content-id="main">
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Bottle-o</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-menu-toggle auto-hide="false">
          <ion-item v-for="item in menuItems()" :key="item.to" :href="getHref(item)" :router-link="getRouterLink(item)" lines="none" detail="false" button>
            <ion-label>
              {{ item.label }}
            </ion-label>
            <ion-icon slot="start" size="small" :name="item.icon"></ion-icon>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
    <div class="menu-bottom">
      <svg class="bottle-o-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 137.9 147.1" xml:space="preserve"><g><circle class="st0" cx="116.3" cy="35.2" r="14.8"/><circle class="st0" cx="95.6" cy="12.6" r="8.7"/><circle class="st0" cx="88.6" cy="37.5" r="5.7"/><path class="st0" d="M55.2 41.1C26.5 41.1 5.4 63 5.4 92.3c0 27.9 24.6 46.8 50.9 46.8 27.1 0 48.7-21.7 48.7-49.6.1-27.1-22.2-48.4-49.8-48.4zm.1 76c-14.9 0-27-12.1-27-27s12.1-27 27-27 27 12.1 27 27-12.1 27-27 27z"/></g></svg>
      <div>
        developed and hosted by <span class="menu-bottom-alenus">Alenus GmbH</span> {{new Date().getFullYear()}}
      </div>
    </div>
  </ion-menu>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonLabel,
  IonMenu,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonMenuToggle,
} from '@ionic/vue'
import { mapGetters } from 'vuex'
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonLabel,
    IonMenu,
    IonTitle,
    IonToolbar,
    IonMenuToggle,
    IonIcon
  },
  computed: {
    ...mapGetters('app', [
      'activePartner',
    ]),
    ...mapGetters('auth', [
      'userprofile'
    ])
  },
  data() {
    return {}
  } ,
  methods: {
    menuItems(){
      let url_path = '';
      if(this.activePartner && this.activePartner.url_path){
        url_path = this.activePartner.url_path;
      }
      return [
        {
          label: 'Startseite',
          to: 'home',
          icon: 'home-outline',
          params: { 'url_path': url_path }
        },
        {
          label: 'Profil',
          to: 'profil',
          icon: 'person-circle-outline'
        },
        {
          label: 'Kontakt',
          to: 'contact',
          icon: 'mail-outline'
        },
        {
          label: 'Nutzungsbedingungen',
          to: 'nutzungsbedingungen',
          icon: 'document-text-outline'
        },
        {
          label: 'Shop',
          to: process.env.VUE_APP_SHOP_BASE_URL + url_path,
          icon: 'storefront-outline',
          external: true
        },
        {
          label: 'Filialfinder',
          to: 'stores',
          icon: 'storefront-outline',
          params: { 'url_path': url_path }
        },
        {
          label: 'Partner',
          to: 'partners',
          icon: 'storefront-outline'
        },
        {
          label: 'Impressum',
          to: 'impressum',
          icon: 'information-circle-outline'
        }
      ];
    },
    getRouterLink(item){
      if(item.external){
        return null;
      }
      return { name: item.to, params: item.params};
    },
    getHref(item){
      if(item.external){
        return item.to;
      }
      return null;
    }
  }

});
</script>
<style>
  .bottle-o-logo {
    /*position: relative;*/
    fill: rgba(33,33,33,.1);
    width: 2.5rem;
    margin: auto auto .5rem;
    /*transform: translate(-50%, 0);*/
  }
  .menu-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: .67rem;
    font-size: .67rem;
    color: rgba(0,0,0,.2);
  }
  
  .menu-bottom-alenus {
    color: rgba(0,0,0,.4);
  }
</style>