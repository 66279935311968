import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import { apiRouteStore } from "@/plugins/apiRoute";
import { httpPluginVuex } from "@/plugins/httpPlugin";
import { app } from '@/store/modules/app'
import { auth } from '@/store/modules/auth'
import { partners } from '@/store/modules/partners'
import { stores } from '@/store/modules/stores'
import { stampCards } from '@/store/modules/stampcards'
import { promotions } from '@/store/modules/promotions'
import { cards } from '@/store/modules/cards'

//create rootStore
export const rootStore = createStore({
  modules: {
    app,
    auth,
    partners,
    cards,
    stores,
    stampCards,
    promotions
  },
  plugins: [
    apiRouteStore(),
    httpPluginVuex(),
    createPersistedState({
      key: 'app',
      paths: ['app.cachedRequests', 'app.activePartnerObj']
    }),
    createPersistedState({
      key: 'auth',
      paths: ['auth']
    }), 
    createPersistedState({          
        key: 'partners',
        paths: ['partners']
    }),
    createPersistedState({
        key: 'stores',
        paths: ['stores']
    }),
    createPersistedState({
        key: 'stampCards',
        paths: ['stampCards']
    }),
    createPersistedState({
      key: 'promotions',
      paths: ['promotions']
    }),
    createPersistedState({
      key: 'cards',
      paths: ['cards']
    }),
  ]
})

export default rootStore