<template>
  <div class="ion-padding">
    <span v-html="privacy"></span>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

  export default {
    name: "TextPrivacyPolicy",
    computed: {
      ...mapGetters('app', [
        'activePartner',
        'baseConfiguration'
      ]),
      ...mapGetters('partners', [
        'partners'
      ]),
    },
    data() {    
      return {
        privacy: null
      };
    },
    props: {
      partner: { type: Object, required: false }
    },
    async created() {
      if(this.partner){
        this.privacy = this.partner.privacy;
        return;
      }
      
      if(this.activePartner && this.activePartner.privacy){
        this.privacy = this.activePartner.privacy;
      }else{
        if(!this.baseConfiguration || !this.baseConfiguration.privacy){
          await this.fetchBaseConfiguration();
        }
        this.privacy = this.baseConfiguration.privacy;
      }
    },
    methods: {
      ...mapActions('app', [
        'fetchBaseConfiguration'
      ]),
    },
  }
</script>