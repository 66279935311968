export const stores = {
    namespaced: true,
    state: () => ({
        stores: []
    }),
    mutations: {
        setStores(state, stores) {
            state.stores = stores
        },
    },
    actions: {
        async fetchStores({ commit, rootGetters }) {
            const stores = await this.$http.get(`/api/v1/partners/${rootGetters['app/activePartnerObj'].id}/stores`)
            
            commit('setStores', stores.data)
        }
    }
}