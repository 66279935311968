import { Ziggy as ApiRoutes } from "@/api/ziggy";
import route from 'ziggy-js';

const apiRoute = (name, params, absolute = false, config = ApiRoutes) => route(name, params, absolute, config)

export const apiRouteVue = {
  install: (app, options) => {
      app.config.globalProperties.$apiRoute = apiRoute
  }
}

export function apiRouteStore (options = {}) {
  return function (store) {    
    store.$apiRoute = apiRoute
  }
}

export default apiRouteVue