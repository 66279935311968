
import { defineComponent, computed, watchEffect, ref } from 'vue'
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { mapState, mapActions, mapMutations, mapGetters, useStore } from 'vuex'
import { Network } from '@capacitor/network'
import { useOnline, throttledWatch, useDocumentVisibility, useInterval } from '@vueuse/core'
import DmMenu from "@/components/DmMenu.vue";
import ModalCtrlMixin from '@/components/modals/ModalCtrl.mixin.vue'
import LinkPartnerModal from "@/components/modals/LinkPartnerModal.vue";

const autoFetchActionTypes = [
  {
    type: 'app/fetchApiEndpoints',
    authRequired: false,
    tenantRequired: false
  },
  {
    type: 'auth/fetchUser',
    authRequired: true,
    tenantRequired: false
  },
  {
    type: 'partners/fetchPartners',
    authRequired: true,
    tenantRequired: false
  },
  {
    type: 'cards/fetchCards',
    authRequired: true,
    tenantRequired: false
  },
  {
    type: 'promotions/fetchPromotions',
    authRequired: true,
    tenantRequired: true
  }
]

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    DmMenu
  },
  mixins: [ModalCtrlMixin],
  setup: () => {
    const store = useStore()
    const visibility = useDocumentVisibility()
    const online = useOnline()
    const isLogedIn = computed(() => store.getters["auth/isLogedIn"])
    const interval = useInterval(1000*60*15,{ controls: true, immediate: false })
    const refreshOnWindowFocus = ref(0)
    const activeTenant = store.getters["app/activePartner"];
    
    watchEffect(() => {
      //TODO: works not perfect
      if(visibility.value === 'visible') {
        refreshOnWindowFocus.value = refreshOnWindowFocus.value + 1;
      }
    })
    
    throttledWatch(
      [online, isLogedIn, refreshOnWindowFocus, interval.counter],
      ([online, isLogedIn, refreshOnWindowFocus, counter]) => {
        interval.resume()
        
        if(online) {          
          autoFetchActionTypes.filter((action) => !!((!action.authRequired || isLogedIn) && (!action.tenantRequired || activeTenant)))
            .forEach((action, index) => {
              window.setTimeout(() => {
                if((!action.authRequired || isLogedIn) && (!action.tenantRequired || activeTenant)) {
                  store.dispatch(action.type)
                }
              }, 500 * index)
            })
        }
      },
      { throttle: 1000 }
    )
    
    interval.resume()
    
    return {
      store,
      online,
      visibility,
      refreshOnWindowFocus
    }
  },
  computed: {
    ...mapState('app', [
      'cancelToken',
      'failedRequests'
    ]),
    ...mapGetters('auth', [
      'isLogedIn',
    ]),
    ...mapGetters('app', [
      'networkStatus',
      'activePartner',
    ]),
    ...mapGetters('partners', [
      'partners'
    ]),
    ...mapGetters('cards', [
      'cards'
    ]),
  },
  methods: {
    ...mapActions('app', [
      'freshNetworkStatus',
      'fetchApiEndpoints'
    ]),
    ...mapActions('cards', [
      'fetchCards'
    ]),
    ...mapActions('partners', [
      'fetchPartners'
    ]),
    ...mapMutations('app', [
      'setNetworkStatus',
    ]),
    cancelAllRequests() {
      this.backgroundRequests.forEach((req) => req.cancel())
    },
    async initNetworkStatus() {
      this.setNetworkStatus(await Network.getStatus())
      Network.addListener('networkStatusChange', (status) => {        
        this.setNetworkStatus(status)
      })
    }
  },
  created() {
    this.initNetworkStatus()
    
    if(this.$route.params.url_path !== undefined){
      let curPartner = this.partners.filter( (partner) => partner.url_path == this.$route.params.url_path && partner.is_active_pos)[0];
      this.$store.commit('app/setActivePartnerObj', curPartner, { root: true });
      if(curPartner === undefined){
        this.$router.push({ name: 'partners'});
      }
    }    
    
    if(this.isLogedIn && this.activePartner && !this.cards.some((card) =>  card.partner_id == this.activePartner.id && card.type === 'digital' && card.active)) {
      this.openModal(LinkPartnerModal, {
        partner: this.partners.filter((partner) =>  partner.id == this.activePartner.id )[0],
        force: true
      });
    }
  },
  watch: {
    networkStatus(newNetworkStatus) {
      if(!newNetworkStatus.connected) {
        //cancel all request when network down?
      }

      if(!newNetworkStatus.connected) {
        //rerun all cachedRequests
      }
    }
  },
});
