<script>
import {modalController} from "@ionic/vue";

export default {
  data() {
    return {
      modalCtrl: null
    }
  },
  methods: {
    async openModal(modalComponent, data = null) {
      let force = data.force;
      if(force === undefined) {
        force = false;
      }
      this.modalCtrl = await modalController
        .create({
          component: modalComponent,
          cssClass: 'dm-modal',
          componentProps: {
            parent: this,
            data
          },
          backdropDismiss: !force
        })
      
      return this.modalCtrl.present();
    },
    async closeModal() {
      await this.modalCtrl.dismiss()
    }
  }
}
</script>