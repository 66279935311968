
import { IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonContent,
  IonMenuButton,
  IonIcon,
  IonImg } from '@ionic/vue'
import { mapGetters } from 'vuex'
import DmMenu from '@/components/DmMenu.vue'
import DmHeader from "@/components/DmHeader.vue";

export default  {
  name: 'BaseLayout',
  components: {
    DmHeader,
    IonMenuButton,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonImg,
    IonBackButton,
    DmMenu,
    IonIcon
  },
  props: {
    title: String,
    waveStyle: String,
  },
  computed: {
    currentRouteName() {
        return this.$route.name + '_page';
    },
    ...mapGetters({
      isLogedIn: 'auth/isLogedIn',
      activePartner: 'app/activePartner'
    }),
    
  }
}
