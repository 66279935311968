import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue';
import i18n  from './i18n'
import store from './store'
import App from './App.vue'
import router from './router';
import './registerServiceWorker'
import apiRoute from '@/plugins/apiRoute'
import httpPlugin from '@/plugins/httpPlugin'
import filters from './helpers/filters'
import { addIcons } from 'ionicons';
import { 
  starOutline, 
  caretForwardOutline, 
  homeOutline, 
  ticketOutline, 
  cashOutline, 
  personCircleOutline, 
  helpOutline, 
  helpCircleOutline, 
  mailOutline, 
  documentTextOutline, 
  storefrontOutline, 
  pencilOutline, 
  informationCircleOutline, 
  waterOutline, 
  cardOutline, 
  logoEuro, 
  lockClosed,
  sparklesOutline,
  ribbonOutline
} from 'ionicons/icons';
// import { RayPlugin } from 'vue-ray';
const { RayPlugin } = require('vue-ray');
const { ray } = require('js-ray');
// import { ray } from 'js-ray';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './assets/css/variables.css';
import './assets/css/core.css';

/* Custom Layout */
import BaseLayout from './components/layout/BaseLayout.vue';

// Vue.config.errorHandler = function(err, vm, info) {
//   console.log(`Error: ${err.toString()}\nInfo: ${info}`);
// }

const app = createApp(App)
  .use(store)
  .use(IonicVue)
  .use(router)
  .use(i18n)
  .use(apiRoute)
  .use(httpPlugin)
  .component('base-layout', BaseLayout)
router.isReady().then(() => {
  app.mount('#app');
});

app.config.errorHandler = (err, vm, info) => {
  console.error(err, info, vm)
  // ray(err, info)
  // console.error(`Error: ${err.toString()}\nInfo: ${info}`);
}




  // app.use(RayPlugin, { interceptErrors: true });



addIcons({
  'home-outline': homeOutline,
  'person-circle-outline': personCircleOutline,
  'help-outline': helpOutline,
  'help-circle-outline': helpCircleOutline,
  'mail-outline': mailOutline,
  'document-text-outline': documentTextOutline,
  'pencil-outline': pencilOutline,
  'storefront-outline': storefrontOutline,
  'information-circle-outline': informationCircleOutline,
  'water-outline': waterOutline,
  'card-outline': cardOutline,
  'ticket-outline': ticketOutline,
  'cash-outline': cashOutline,
  'logo-euro': logoEuro,
  'caret-forward-outline': caretForwardOutline,
  'star-outline' : starOutline,
  'lock-closed' : lockClosed,
  'sparkles-outline' : sparklesOutline,
  'ribbon-outline' : ribbonOutline,
});

app.config.globalProperties.$filters = filters