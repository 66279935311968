<template>
  <base-modal :title="'Datenschutz'">
    <text-privacy-policy :partner="partner"></text-privacy-policy>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal";
import TextPrivacyPolicy from '@/components/text/TextPrivacyPolicy.vue'

export default {
  name: "ModalPrivacyPolicy",
  components: {
    BaseModal,
    TextPrivacyPolicy
  },
  props:{
    partner: { type: Object, required: false }
  }
}
</script>

<style scoped>

</style>