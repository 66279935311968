export const stampCards = {
    namespaced: true,
    state: () => ({
        data: []
    }),
    mutations: {
        setStampCards(state, stampCards) {
            state.data = stampCards
        },
    },
    getters: {
        activeStampCards(state) {
            return state.data
        },
        activeStampCardsCount(state, getters) {
            return getters.activeStampCards.length
        },
        stampCard: (state) => (stampCardId) => {
            return state.data.find(card => card.id === stampCardId)
        },
        stampCardEventCount: (state, getters) => (stampCardId) => {
            return getters.stampCard(stampCardId)
        },
    },
    actions: {
        async fetchStampCards({ commit, rootGetters }) {
            const stampCards = await this.$http.get(`/api/v1/customer/stampcards`)

            commit('setStampCards', stampCards.data)
        }
    }
}