
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'Tabs',
  components: {
    IonRouterOutlet,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonIcon
  },
  data() {
    return {
      user: {
        analysisToggle: true,
      }
    }
  },
  computed: {
    ...mapGetters('app', [
      'activePartner',
    ]),
    ...mapGetters('auth', [
      'userprofile',
      'analyticsAllowed'
    ]),
  }

})
