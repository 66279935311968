import fetch from '@/api/fetch'

fetch.get = (url, ...options) => {
    options.method = 'GET'
    
    return fetch(url, options)
}

fetch.post = (url, ...options) => {
    options.method = 'POST'
    
    return fetch(url, options)
}

fetch.put = (url, ...options) => {
    options.method = 'PUT'

    return fetch(url, options)
}

fetch.patch = (url, ...options) => {
  options.method = 'PATCH'

  return fetch(url, options)
}

export default {
  install: (app, options) => {
      app.config.globalProperties.$http = fetch
  }
}

export function httpPluginVuex (options = {}) {
  return (store) => {
      return store.$http = fetch
  }
}