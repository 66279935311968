import {toastController} from '@ionic/vue'

export default async (error) => {
    const toast = await toastController.create({
        color: 'danger',
        duration: 2500,
        message: 'error',
        showCloseButton: true
    });
    
    error.response.json().then(async (json) => {
        const messages = Object.entries(json.errors).map(v => `<div>${v[1]}</div>`).join('')
        toast.message = `<div>${json.message.concat(messages)}</div>`
        await toast.present()
    })    
}