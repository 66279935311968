<template>
  <ion-header v-if="title">
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-button v-if="!force" @click="closeModal()" slot="end" fill="clear" shape="round" color="light">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#000" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"/></svg>
      </ion-button>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <slot></slot>
  </ion-content>
</template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, modalController } from '@ionic/vue';

export default {
  name: 'Modal',
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButton },
  props: {
    parent: { type: Object, default: null },
    title: { type: String, default: '' },
    force: { type: Boolean, default: false }
  },
  data() {
    return {
      content: null,
      data: null
    }
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
  }
}
</script>