import { createRouter, createWebHistory } from '@ionic/vue-router';
import Tabs from '../views/Tabs.vue';
import Store from "../store"


const routes = [
  {
    name: '/',
    path: '/',
    redirect: '/partners'
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    name: 'Registration',
    path: '/registration',
    component: () => import('@/views/Registration.vue'),
    meta: { requiresAuth: false }
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    name: 'tutorial',
    path: '/tutorial',
    component: () => import('@/views/Tutorial.vue'),
    meta: { requiresAuth: false }
  },
  {
    name: 'partners',
    path: '/partners',
    props: true,
    component: () => import('@/views/Partners.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: '/app',
    component: Tabs,
    meta: { requiresAuth: true },
    children: [

      {
        name: 'home',
        path: '/:url_path/home',
        component: () => import('@/views/Home.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'promotion',
        path: '/:url_path/promotion',
        component: () => import('@/views/Promotion.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'stores',
        path: '/:url_path/stores',
        component: () => import('@/views/Stores.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'promotion.show',
        path: '/:url_path/promotion/:id',
        component: () => import('@/components/ui/Promotions/ActionCardsDetails.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'stamp-card.show',
        path: '/:url_path/stamp-cards/:id',
        component: () => import('@/components/ui/Promotions/StampCardsDetails.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'coupon',
        path: '/:url_path/coupon',
        component: () => import('@/views/Coupon.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'coupon.show',
        path: '/:url_path/coupon/:id',
        component: () => import('@/components/ui/Promotions/CouponCardsDetails.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'contact',
        path: '/kontakt',
        component: () => import('@/views/Contact.vue'),
        meta: { requiresAuth: false }
      },
      {
        name: 'impressum',
        path: '/impressum',
        component: () => import('@/views/LegalDisclosure.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/agb',
        component: () => import('@/views/Agb.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/datenschutz',
        component: () => import('@/views/PrivacyPolicy.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'nutzungsbedingungen',
        path: '/nutzungsbedingungen',
        component: () => import('@/views/TermOfUse.vue'),
        meta: { requiresAuth: false }
      },
      {
        name: 'teilnahmebedingungen',
        path: '/teilnahmebedingungen',
        component: () => import('@/views/ConditionsOfParticipation.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'customer-card',
        path: '/:url_path/customer-card',
        component: () => import('@/views/CustomerCard.vue'),
        meta: { requiresAuth: true }
      },
      {
        // in tab-nav
        name: 'stamp-card',
        path: '/:url_path/stamp-card',
        component: () => import('@/views/StampCards.vue'),
        meta: { requiresAuth: true }
      },
      {
        name: 'profil',
        path: '/profil',
        component: () => import('@/views/Profile.vue'),
        meta: { requiresAuth: true }
      },
    ]
  }
];

const withoutPartner = ['impressum', 'tutorial'];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if ((to.meta.requiresAuth && !Store.getters['auth/isLogedIn'])) {
    next({ name: 'Login' })
  } else {    
    next()
  }
})

router.beforeEach((to, from, next) => {
  if(withoutPartner.includes(to.name)){
    next();
  }

  if(to.params.url_path !== undefined){
    let partners = Store.getters['partners/partners'];
    let curPartner = partners.filter( (partner) => partner.url_path === to.params.url_path && partner.is_active_pos)[0];
    Store.commit('app/setActivePartnerObj', curPartner, { root: true });
    if(curPartner === undefined){
      next({ name: 'partners' })
    }
  }
  
  if (Store.getters['auth/isLogedIn'] && to.name !== 'partners' && !Store.getters['app/activePartner']) {
    next({ name: 'partners' })
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if(withoutPartner.includes(to.name)){
    next();
  }
    
  if (Store.getters['auth/isLogedIn'] 
    && to.redirectedFrom 
    && to.redirectedFrom.name === '/' 
    && to.name !== 'home' 
    && Store.getters['app/activePartner'] 
    && Store.getters['app/activePartner'].url_path) {
    next({ name: 'home', params: { url_path: Store.getters['app/activePartner'].url_path} });
  } else {
    next();
  }
})

export default router
