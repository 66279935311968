import {toastController} from '@ionic/vue'
import {messages} from "workbox-strategies/utils/messages";

export default async (error) => {
    const toast = await (await toastController.create({
        color: 'danger',
        duration: 2500,
        message: 'kritischer Fehler',
        showCloseButton: true
    })).present();
}