export const cards = {
    namespaced: true,
    state: () => ({
        data: []
    }),
    mutations: {
        setCards(state, cards, getter, test) {
            state.data = cards
        },
    },
    getters: {
      activeCard(state, getter, rootState) {
        return state.data.filter((c) => c.partner_id === rootState.app.activePartnerObj.id)[0];
      },
      card: (state) => (id) => {
        return state.cards.find((card) => card.id === id)
      },
      cards(state) {
          return state.data
      },
      digitalCardForPartner: (state) => (partner_id) => {
        const cards = state.data
          .filter(({type}) => type === 'digital')
          .filter(i => i.partner_id === partner_id)
        
        return (cards.length) ? cards[0] : null
      }
    },
    actions: {
        async fetchCards({commit}) {
          const cards = await this.$http.get(this.$apiRoute('api.cards.index'))
          
          commit('setCards', cards.data)          
        }
    }
}