import moment from "moment";

const filters = {
  dateTimeToDate(value) {
    return moment(value).format('DD.MM.YYYY');
  },
  formatCurrency(value) {
    return value.replace('.', ',');
  }
}
export default filters;