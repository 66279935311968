import router from '@/router';
import store from "@/store";

export const auth = {
    namespaced: true,
    state: () => ({
        bearerToken: null,
        userprofile: {
            email: null,
            email_verified: true,
            customer: null,
            settings: null
        },
    }),
    getters: {
        isLogedIn(state) {
            return !!state.bearerToken?.length
        },
        userprofile(state) {
            return state.userprofile
        },
        activeCardByPartner: (state) => (partner) => {
            return state.userprofile.customer.cards
                .filter(({active}) => active)
                .filter(({partner_id}) => partner_id === partner.id)[0]
        },
        analyticsAllowed(state) {
          return (state.userprofile.settings?.filter((s) => s.key === 'analytics')[0]?.value === "1")
        }
    },
    mutations: {
        setToken (state, token) {
            state.bearerToken = token
        },
        setUserprofil (state, profil) {
            state.userprofile = profil
        },
        setUserMail (state, email) {
            state.userprofile.email = email
        },
        setAnalytics: function (state, value) {
          state.userprofile.settings = state.userprofile.settings.map((s) => {
            if (s.key === 'analytics') {
              s.value = (value) ? '1' : '0'
            }

            return s
          });
        }
    },
    actions: {
        async login (context, payload) {
            return this.$http(this.$apiRoute('login'), {
                method: 'POST',
                body: JSON.stringify(payload)
            }).then((response) => {
                if(response.token) {
                    context.commit('setToken', response.token)
                }

                return response
            })
        },
        async logout({commit}) {
            this.$http.get('/logout')
            commit('setToken', null)
            store.commit('app/setActivePartnerObj', null, {root: true});
            router.push('/login')
        },
        async register ({commit}, payload) {
            return this.$http(this.$apiRoute('register'), {
              method: 'POST',
              body: JSON.stringify(payload)
            })
        },
        async changeAnalytics({ commit, state, dispatch }, payload) {
          this.$http(this.$apiRoute('api.profile.update', { profile:state.userprofile.id }), {
            method: 'PUT',
            body: JSON.stringify(payload)
          }).then((response) => {
            commit('setAnalytics', payload.value)
            return response
          })
        },
        async fetchUser({ commit }) {
            return this.$http('/api/v1/profile').then((json) => {
                commit('setUserprofil', json.data)
            })            
        },
        async changeMail({ commit }, payload) {
            //TODO: check OfflineSupport
            this.$http('/api/v1/change-mail', {
              method: 'PATCH',
              body: JSON.stringify(payload)
            }).then(() => {
                commit('setUserMail', payload.email)
            })
        },
        async changePassword({ commit }, payload) {
            //TODO: check OfflineSupport
            this.$http(this.$apiRoute('api.users.change-password'), {
              method: 'PUT',
              body: JSON.stringify(payload)
            })
        },
        async changeBirthday({ commit }, payload) {
            //TODO: check OfflineSupport
            this.$http.post('/api/v1/users/change-birthday', {
              body: JSON.stringify(payload)
          })
        },
      
      
        async sendContactEmail({ commit }, payload) {
          return this.$http(this.$apiRoute('api.send-contact-form'), {
            method: 'POST',
            body: JSON.stringify(payload)
          }).then((response) => {
            return response
          })
        },
      
      
      
        async forgotPassword({ commit }, payload) {
          return this.$http(this.$apiRoute('password.forgot'), {
            method: 'POST',
            body: JSON.stringify(payload)
          }).then((response) => {
            return response
          })
        },
        async deleteUser({ commit, state, dispatch }, payload) {
          this.$http(this.$apiRoute('api.users.destroy', { user:state.userprofile.id }), {
            method: 'DELETE'
          }).then((response) => {
            dispatch('logout')
            return response
          })
        },
      
        async resetPassword({ commit }, payload) {
        return this.$http(this.$apiRoute('password.reset'), {
          method: 'POST',
          body: JSON.stringify(payload)
        }).then((response) => {
          return response
        })
      },
    }
}