import fetch from 'unfetch'
import store from "@/store/index"
import exceptionHandler from "@/api/middlewares/ExceptionHandler"

const defaults = {
    headers: { "Accept": "application/json" }
}

const baseUrl = process.env.VUE_APP_API_BASE_URL

function checkStatus(response) {
    if (response.ok) return response

    const error = new Error(response.statusText)
    error.response = response
    throw error
}

export default async (url, options) => {
    const requestHeaders = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'mode': 'no-cors',
        'credentials': 'omit',
        'Access-Control-Allow-Origin': '*'
    }
  
    if(store.state.app.activePartnerObj && store.state.app.activePartnerObj.id){
      requestHeaders["X-Tenant"] = store.state.app.activePartnerObj.id;
    }
    
    if(store.state.auth.bearerToken) {
        requestHeaders.withCredentials = true
        requestHeaders.credentials = 'include'
        requestHeaders.Authorization = `Bearer ${store.state.auth.bearerToken}`
    }
    
    options = {
        headers: requestHeaders,
        ...options
    }
    
    if (typeof url === 'string' && url.startsWith('/')) {
        url = baseUrl + url
    }

    return await fetch(url, options)
        .then(checkStatus)
        .catch(exceptionHandler)
        .then((res) => {
            if (res?.ok) {
                return res.json();
            }
        })
}

export {
    baseUrl
}